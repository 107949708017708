<script setup>
const userStore = useUserStore()
const config = useRuntimeConfig()
const { countryLocalePath } = useCountry()
</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        class="px-3 menu-button"
        data-test-id="menu-button"
        variant="plain"
        color="black-800"
        :ripple="false"
        size="large"
      >
        {{ userStore.data.firstname }} {{ userStore.data.lastname }}
        <v-icon icon="$expand" right size="18" />
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        :title="$t('layout.accountMenu.profile')"
        :subtitle="userStore.data?.email"
        :to="countryLocalePath('/account/profile')"
      />
      <v-list-item
        v-if="['pms.general', 'pms.calendar'].every(key => userStore.data?.featureKeys.includes(key))"
        :title="$t('layout.accountMenu.administration')"
        href="/legacy/account/administration"
      />
      <v-list-item
        v-if="['pms.general', 'pms.calendar'].every(key => userStore.data?.featureKeys.includes(key))"
        :title="$t('layout.accountMenu.appointment')"
        :href="`${config.public.pmsUrl}/calendar`"
      />
      <v-list-item
        :title="$t('customerReference.plural')"
        :to="countryLocalePath('/account/references')"
      />
      <v-list-item
        :title="$t('logout')"
        @click="userStore.logout()"
      />
    </v-list>
  </v-menu>
</template>

<style scoped lang="sass">
.menu-button
  letter-spacing: 0
  opacity: 1
  font-weight: 400
</style>
