<script setup lang="ts">
const supplierStore = useSupplier()
const userStore = useUserStore()
const cartStore = useCartV2()
const orderHistoryStore = useOrderHistoryStore()
const featureSettings = useFeatureSettingsStore()
const config = useRuntimeConfig()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
const { ogImage, favicon, title, description } = useHeaderInformation()

useHead({
  link: [
    { rel: 'icon', href: favicon.value, id: 'favicon' },
  ],
  meta: [
    { property: 'google-adsense-account', content: `${config.public.googleAdsenseId}` },
    { property: 'og:title', content: title.value },
    { property: 'description', content: description.value },
    { property: 'og:description', content: description.value },
    { property: 'og:image', content: `${config.public.baseUrl}/${ogImage.value}` },
    { property: 'twitter:card', content: 'summary_large_image' },
  ],
  script: [
    { src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js' },
  ],
})

await Promise.all([
  userStore.getProfileData(),
  supplierStore.loadSuppliers(),
  // init cart count
  cartStore.loadCartCount(),
  orderHistoryStore.getLastOrderedCount(),
])

useNuxtApp().$sentry?.setUser(userStore.data?.handle)

// load feature setting here, so we have the settings when the user is not logged in as well, e.g. during registration
featureSettings.loadSettings()

// Todo: Add feature flag initialization here
// load feature-flags and reload them regularly on the client
// await featureFlagsStore.loadFeatureFlags()
// if (import.meta.client && config.public.featureFlagsRefreshIntervalSeconds >= 10)
//   useIntervalFn(featureFlagsStore.loadFeatureFlags, config.public.featureFlagsRefreshIntervalSeconds * 1000)
const categoryStore = useCategoryGroup()
categoryStore.getCategoryGroups()
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :name="meta.name" :property="meta.property" :content="meta.content" />
      </template>
    </Head>

    <Body>
      <BaseContent :user-store="userStore" theme="heilandTheme">
        <slot />
      </BaseContent>
      <!-- Todo: Add removed Feature Viewer -->
    </Body>
  </Html>
</template>

<style>
.main-container {
  padding: 24px;
  max-width: 1920px;
}
.main-wrapper {
  background-color: rgb(var(--v-theme-vm-grey-2));
  min-height: 100%;
}
</style>
