<script setup lang="ts">
import IconCart from '~icons/mdi/cart'
import IconHeart from '~icons/mdi/heart'
import IconHelpCircleOutline from '~icons/mdi/help-circle-outline'
import IconHistory from '~icons/mdi/history'
import IconLogout from '~icons/mdi/logout'
import IconMenu from '~icons/mdi/menu'

const constants = useConstants()
const userStore = useUserStore()
const cartStore = useCartV2()
const categories = useCategoryGroup()

const { t } = useI18n()
const { countryLocalePath } = useCountry()
const { $intercom } = useNuxtApp()

const mobileMenuOpen = ref(false)
watch(mobileMenuOpen, (value) => {
  if (!value)
    $intercom.hide()
})

interface MenuItem { title: string, to?: string, prependIcon?: any, class?: string, onClick?: (() => unknown), activeQueryParam?: string }
const menuItems = ref<MenuItem[]>([])

function clickOnMenuItem(item: MenuItem) {
  mobileMenuOpen.value = false
  if (item.onClick)
    item.onClick()
}

watch([() => userStore.data, () => categories.categoryGroup], () => {
  menuItems.value = []
  // Clinic Chain Member
  if (userStore.data.clinicChain) {
    menuItems.value.push({
      title: userStore.data.clinicChain.name,
      to: countryLocalePath('/clinic-chain'),
      activeQueryParam: constants.QUERY_PARAMS.categories,
    })
  }

  if (categories.categoryGroup.length > 0) {
    categories.categoryGroup.forEach((category) => {
      menuItems.value.push({
        title: t(category.i18nKey),
        to: countryLocalePath(`/${category.handle}`),
      })
    })
  }

  menuItems.value.push({
    title: t('layout.menuSecondary.suppliers'),
    to: countryLocalePath('/suppliers'),
  })

  menuItems.value.push({
    title: t('help'),
    prependIcon: IconHelpCircleOutline,
    class: 'mobile-intercom-launcher',
    onClick: () => {
      $intercom.boot({
        custom_launcher_selector: '.mobile-intercom-launcher',
        hide_default_launcher: true,
      })
      setTimeout(() => $intercom.show(), 500)
    },
  })

  menuItems.value.push({
    title: t('logout'),
    prependIcon: IconLogout,
    onClick: () => { userStore.logout() },
  })
}, { immediate: true })
</script>

<template>
  <v-bottom-navigation bg-color="red-400" elevation="0" grow class="w-100 mobile-menu-wrapper">
    <v-bottom-sheet v-model="mobileMenuOpen">
      <template #activator="{ props }">
        <v-btn v-bind="props" class="pa-0">
          <v-icon :icon="IconMenu" />
          {{ $t('menu') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          :title="item.title"
          :to="item.to ? item.to : undefined"
          :prepend-icon="item.prependIcon ? item.prependIcon : undefined"
          :class="item.class ? item.class : undefined"
          @click="clickOnMenuItem(item)"
        />
      </v-list>
    </v-bottom-sheet>
    <v-btn :to="countryLocalePath('/order-history')" class="pa-0">
      <v-icon :icon="IconHistory" />
      {{ $t('orders') }}
    </v-btn>

    <v-btn :to="countryLocalePath('/product-favorites')" class="pa-0">
      <v-icon :icon="IconHeart" />
      {{ $t('favorites') }}
    </v-btn>
    <v-btn v-if="cartStore.totalAmount" :to="countryLocalePath('/cart')" class="pa-0">
      <v-badge :max="99" :content="cartStore.totalAmount" offset-x="-5" color="heiland-light-blue">
        <v-icon :icon="IconCart" />
      </v-badge>
      {{ $t('cart.title') }}
    </v-btn>
    <v-btn v-else color="white" :to="countryLocalePath('/cart')">
      <v-icon :icon="IconCart" />
      {{ $t('cart.title') }}
    </v-btn>
  </v-bottom-navigation>
</template>
