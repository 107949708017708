<script setup lang="ts">
const props = defineProps<{
  userStore: ReturnType<typeof useUserStore>
}>()
const orderHistory = useOrderHistoryStore()
const featureSettingsStore = useFeatureSettingsStore()
const gtm = useGtm()
const categoryStore = useCategoryGroup()

const { countryLocalePath, country } = useCountry()
const { locale } = useI18n()
const router = useRoute()
const categoryGroupReverseI18n = useCategoryI18nReverse('enum.product_category_group.')
const currentTab = ref(router.path)

function trackCampaignClick(campaignTitle: string, campaign_id: string) {
  gtm?.trackEvent({
    event: 'campaign_click',
    campaign_page: campaignTitle,
    campaign_id,
  })
}

// Category menu
enum MENU_ITEM_TYPE {
  MENU = 'menu',
}

const menuItems = computed(() => {
  const items: any[] = []

  if (orderHistory.totalOrders > 0) {
    items.push({
      to: countryLocalePath('/order-history'),
      i18nKey: 'orderHistory.title',
    })
  }

  if (props.userStore.data.clinicChain) {
    items.push({
      to: countryLocalePath('/clinic-chain'),
      label: props.userStore.data.clinicChain.name,
    })
  }

  const sortedCategoryGroup = [...categoryStore.categoryGroup].sort(sortByHandle(['pharma', 'practice_supply', 'food']))

  if (sortedCategoryGroup.length > 0) {
    sortedCategoryGroup.forEach((category) => {
      items.push({
        to: countryLocalePath(`/${categoryGroupReverseI18n.translateNormalized(category.handle)}`),
        i18nKey: category.i18nKey,
      })
    })
  }

  /*  if (categoryStore.categoryGroup.length > 0) {
    categoryStore.categoryGroup.sort(sortByHandle(['pharma', 'practice_supply', 'food'])).forEach((category) => {
      items.push({
        to: countryLocalePath(`/${categoryGroupReverseI18n.translateNormalized(category.handle)}`),
        i18nKey: category.i18nKey,
      })
    })
  } */

  items.push({
    i18nKey: 'layout.menuSecondary.suppliers',
    to: countryLocalePath('/suppliers'),
  })
  items.push({
    to: countryLocalePath('/product-favorites'),
    i18nKey: 'layout.menuSecondary.productFavorites',
  })

  if (featureSettingsStore.marketing?.campaigns?.length > 0) {
    items.push({
      type: MENU_ITEM_TYPE.MENU,
      i18nKey: 'layout.menuSecondary.campaigns',
      nestedListData: featureSettingsStore.marketing?.campaigns.map(campaign => ({
        props: {
          title: campaign.title,
          to: countryLocalePath(campaign.to),
          onClick: () => trackCampaignClick(campaign.title, campaign.to),
        },
      })),
    })
  }

  return items
})
const menusOpen = reactive<boolean[]>([])

const helpUrl = computed(() => {
  switch (country.value) {
    case 'DE':
      return 'https://help.heiland.com/de-form/'
    default:
      return `https://help.vetmazing.com/${locale.value}`
  }
})
</script>

<template>
  <div class="secondary-menu-wrapper">
    <v-container class="d-flex justify-space-between align-center pa-0">
      <v-tabs v-model="currentTab" class="menu-secondary" center-active show-arrows hide-slider height="56">
        <div v-for="(item, index) in menuItems" :key="index">
          <v-menu
            v-if="item.type === MENU_ITEM_TYPE.MENU"
            :key="`menu-${index}`"
            v-model:menu-open="menusOpen[index]"
            :close-on-content-click="true"
            max-height="800px"
          >
            <template #activator="{ props }">
              <v-tab
                v-bind="props"
                class="menu-secondary__tab text-medium-emphasis px-2"
                :href="!item.to && item.path ? item.path : null"
                :to="item.to ? item.to : null"
                :value="item.path || item.to"
                variant="plain"
                style="opacity: 1"
                :ripple="false"
                size="large"
              >
                <span v-if="item.i18nKey">{{ $t(item.i18nKey) }}</span>
                <span v-else>{{ item.label }}</span>
                <v-icon icon="$expand" right size="18" />
              </v-tab>
            </template>

            <v-list>
              <v-list-item
                v-for="(nestedItem, nestedIndex) in item.nestedListData"
                :key="nestedIndex"
                :to="nestedItem.props.to"
              >
                <p class="text-body-1">
                  {{ nestedItem.props.title }}
                </p>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tab
            v-else
            :key="`tab-${index}`"
            variant="plain"
            size="large"
            class="menu-secondary__tab text-medium-emphasis px-2"
            :href="!item.to && item.path ? item.path : null"
            :to="item.to ? item.to : null"
            :value="item.path || item.to"
            :ripple="false"
            style="opacity: 1;"
          >
            <span v-if="item.i18nKey">{{ $t(item.i18nKey) }}</span>
            <span v-else>{{ item.label }}</span>
          </v-tab>
        </div>
      </v-tabs>

      <div v-if="userStore.isAuthenticated" class="d-flex">
        <v-btn
          v-if="helpUrl"
          variant="plain"
          size="large"
          class="mx-0"
          color="black-800"
          target="_blank"
          :href="helpUrl"
          :ripple="false"
          style="letter-spacing: 0px; opacity: 1"
        >
          {{ $t('help') }}
        </v-btn>
        <LayoutAccountMenu v-if="userStore.data" />
      </div>
    </v-container>
  </div>
</template>

<style scoped>
.secondary-menu-wrapper {
  border-bottom: 2px solid rgb(var(--v-theme-red-50));
  height: 56px;
  background-color: white;
}
a,
button {
  color: rgb(var(--v-theme-black-800)) !important;
  letter-spacing: 0px;
  font-weight: 400;
}
</style>
